<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="6"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
            @click:clear="limpaFornecedores()"
          />
        </v-col>
        <v-col
          :cols="12"
          sm="6"
          md="4"
        >
          <v-autocomplete
            v-model="autoCompleteFornecedorModel"
            :readonly="!empresaClienteModelId"
            :items="autoCompleteFornecedorItens"
            :loading="autoCompleteFornecedorLoading"
            :search-input.sync="autoCompleteFornecedorCampoBusca"
            :filter="filterFornecedorAc"
            :label="$t('DespesaForm.fornecedor')"
            hide-no-data
            item-text="nome"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-menu
            ref="menuDataCompra"
            v-model="menuDataCompra"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataCompraFormatted"
                :label="$t('DespesaForm.dataCompra')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                prepend-icon="mdi-calendar-text"
                @blur="objEditar.dataCompra = parseDate(dataCompraFormatted)"
                v-on="on"
                @click:append="objEditar.dataCompra = null"
              />
            </template>
            <v-date-picker
              v-model="objEditar.dataCompra"
              no-title
              dark
              @input="menuDataCompra = false"
            />
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-switch
            v-model="objEditar.pago"
            :label="$t('DespesaForm.pago')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.valor"
            :label="$t('DespesaForm.valor')"
            class="purple-input"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.formaPagamento"
            :label="$t('DespesaForm.formaPagamento')"
            class="purple-input"
            prepend-icon="mdi-ballot-outline"
          />
        </v-col>
        <!-- messages="Este campo será excluído" -->
        <v-col
          v-if="false"
          cols="12"
          sm="6"
          md="4"
        >
          <caixa-select 
            v-model="objEditar.idCaixa" 
            required
            :id-empresa-cliente="idEmpresaCliente"
            adiciona-valor-selecione
          />
        </v-col>
        <v-col
          v-if="false"
          cols="12"
          sm="6"
          md="4"
        >
          <forma-pagamento-select
            v-model="objEditar.idFormaPagamento" 
            required
            :id-empresa-cliente="idEmpresaCliente"
            adiciona-valor-selecione
            filtra-despesas
            @input="changeFormaPagamento"
          />
        </v-col>

        <v-col
          v-if="permiteParcelamento"
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.valorEntrada"
            :label="$t('DespesaForm.valorEntrada')"
            class="purple-input"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>

        <v-col
          v-if="permiteParcelamento"
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.quantidadeParcelas"
            :label="$t('DespesaForm.quantidadeParcelas')"
            class="purple-input"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="objEditar.observacao"
            :label="$t('DespesaForm.observacao')"
            class="purple-input"
            prepend-icon="mdi-account-edit"
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '../services/DespesasService'
  import FornecedoresService from '../services/FornecedoresService'
  import { mapState } from 'vuex'
  import DateUtils from '../utils/DateUtils'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import EmpresaClientesService from '@/services/EmpresaClientesService'
  import CaixaSelect from  '../components/CaixaSelect'
  import FormaPagamentoSelect from  '../components/FormaPagamentoSelect'
  import FormaPagamentoService from '../services/FormaPagamentoService'

  export default {
    components: {
      EmpresaClienteAutoComplete,
      CaixaSelect,
      FormaPagamentoSelect
    },
    data: () => ({
      menuDataCompra: false,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      fornecedorModelId: null,
      autoCompleteFornecedorModel: null,
      autoCompleteFornecedorItens: [],
      autoCompleteFornecedorLoading: false,
      autoCompleteFornecedorCampoBusca: '',
      permiteParcelamento: false,
      objPadrao: {
        observacao: '',
        valor: 0,
        pago: true,
        dataCompra: null,
        idEmpresaCliente: null,
        idFormaPagamento: null,
        idCaixa: null,
        valorEntrada: null,
        quantidadeParcelas: null
      },
      objEditar: {
        observacao: '',
        valor: 0,
        pago: true,
        dataCompra: null,
        idEmpresaCliente: null,
        idFormaPagamento: null,
        idCaixa: null,
        valorEntrada: null,
        quantidadeParcelas: null
      },
      isEditting: true
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPagina () {
        return this.$i18n.t('DespesaForm.titulo')
      },
      dataCompraFormatted: function () {
        return DateUtils.formatDate(this.objEditar.dataCompra);
      },
    },
    watch: {
      autoCompleteFornecedorModel(val) {
        if (val === null || !val) {
          this.fornecedorModelId = null;
        } else {
          this.fornecedorModelId = val.id;
        }
      },

      autoCompleteFornecedorCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaFornecedor(val)
      },
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)

        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then((res) => {
            this.objEditar.EmpresaCliente = res.data
            this.empresaClienteModel = res.data
          })
        }
      }
    },
    methods: {
      changeFormaPagamento(){
        this.permiteParcelamento = false
        if(!this.objEditar.idFormaPagamento){
          return
        }
        FormaPagamentoService.findById({id:this.objEditar.idFormaPagamento})
          .then(fp => {
            this.permiteParcelamento = fp.data.permiteParcelamento
          })
      },
      parseDate (date) {
        return DateUtils.parseDate(date)
      },
      limpaFornecedores () {
        this.autoCompleteFornecedorItens = []
        this.autoCompleteFornecedorModel = null
      },
      saveContinueEvent () {
        if (this.idEmpresaCliente) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      buscaFornecedor (param) {
        this.autoCompleteFornecedorLoading = true
        FornecedoresService.buscaFornecedoresPorParam(
          param,
          this.objEditar.idEmpresaCliente
        )
          .then((fornecedores) => {
            this.autoCompleteFornecedorItens = fornecedores.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos fornecedores ' + error
            })
          })
          .finally(() => {
            this.autoCompleteFornecedorLoading = false
          })
      },
      filterFornecedorAc (item, queryText, itemText) {
        return true
      },
      atualizar (registro) {
        try {
          if (this.autoCompleteFornecedorModel) {
            registro.idFornecedor = this.autoCompleteFornecedorModel.id
          }

          Servico.update(registro).then((res) => {
            if (!res) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (res.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + res.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              this.closeDialog()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao:
              'Não foi possível concluir a atualização, tente mais tarde\n' +
              error
          })
        }
      },
      closeDialog () {
        this.$router.push('/despesas')
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao:
              this.$t('padrao_ocorreu_um_erro_ultima_operacao') +
              ':\n ->' +
              error
          })
        }
      },
      inserir (registro, manterNestaTela) {
        try {
          if (this.autoCompleteFornecedorModel) {
            registro.idFornecedor = this.autoCompleteFornecedorModel.id
          }
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (manterNestaTela) {
                this.editar(response.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Despera:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then((registro) => {
            this.objEditar = registro.data
            this.isEditting = true

            this.empresaClienteModel = this.objEditar.EmpresaCliente
            this.empresaClienteModelId = this.objEditar.idEmpresaCliente
            if (this.objEditar.idFornecedor) {
              FornecedoresService.findById({ id: this.objEditar.idFornecedor }).then(
                (fornecedor) => {
                  if (fornecedor.id) {
                    this.autoCompleteFornecedorModel = fornecedor
                    this.autoCompleteFornecedorItens[0] = fornecedor
                    this.autoCompleteFornecedorCampoBusca = fornecedor.nome
                  } else if (fornecedor.data.id) {
                    this.autoCompleteFornecedorModel = fornecedor.data
                    this.autoCompleteFornecedorItens[0] = fornecedor.data
                    this.autoCompleteFornecedorCampoBusca = fornecedor.data.nome
                  }
                }
              )
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
